import axios from 'axios';
import { Configuration, OpenAIApi } from 'openai';

// Resto do código do seu arquivo

const configuration = new Configuration({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  });
const openai = new OpenAIApi(configuration);

//...

export const GerarDietaPrompt = async (infoUsuario) => {
    const response = await openai.createCompletion({
        model: "text-davinci-003",
        prompt: `Haja como um nutricionista e me de um plano alimentar de manha, meio dia, tarde e noite para uma pessoa com as seguintes caracteristicas:
        - ${infoUsuario.altura}m de altura;
        - ${infoUsuario.peso}kg;
        - Com o objetivo de ${infoUsuario.objetivo};
        - Com intolerancia a: ${infoUsuario.intolerancia}.
        Mostre sua quantidade(unidade ou peso). Deve ter 3 variedades em cada horario. (Me de listados e cada item iniciando com um hifen (-) e com nomes 100% em portugues. Separe por "MANHA", "MEIO DIA", "TARDE" e "NOITE". Tambem coloque o valor total por periodo e no final)`,
        temperature: 1,
        max_tokens: 550,
        top_p: 1,
        frequency_penalty: 0,
        presence_penalty: 0,
      })
      

    return response.data.choices[0].text.trim();
  
};

export const TestePrompt = async (obj, objMetaDiaria) => {
  const response = await openai.createCompletion({
      model: "text-davinci-003",
      prompt: `Haja como um nutricionista e me de um plano alimentar de manha, meio dia, tarde e noite para uma pessoa com as seguintes caracteristicas:
      - ${obj.altura}m de altura;
      - ${obj.kg}kg;
      - Com o objetivo de ${obj.objetivo};
      - Com intolerancia a: ${obj.intolerancia}.
      - Meta diaria: ${objMetaDiaria.proteina}g de Proteinas, ${objMetaDiaria.carboidrato}g de Carboidrato e ${objMetaDiaria.lipidios}g de Lipidios
      -Preferencia: Adicione pelo menos 1 doce na minha dieta;

      Mostre o valor aproximado de cada refeicao e sua quantidade(unidade ou peso). 
      
      Deve ter 3 variedades em cada horario (3 itens na lista apenas, um embaixo do outro). 
      Me de listados e cada item iniciando com um hifen (-) e com nomes 100% em portugues. Use o hifen apenas para quando for pular de linha, ou seja, de um item para o outro, as demais separaçoes utilize outro separador. 
      Separe por "MANHA", "MEIO DIA", "TARDE" e "NOITE". Tambem coloque o valor total por periodo, sempre nessa ordem: 
      PERIODO:, (sempre utilize : para finalizar essa linha)
      ITEM 1;  (sempre utilize ; para finalizar a linha, isso é o mais importante)
      ITEM 2;  (sempre utilize ; para finalizar a linha, isso é o mais importante)
      ITEM 3;  (sempre utilize ; para finalizar a linha, isso é o mais importante) 
      VALOR: R$99,99 *APENAS O VALOR TOTAL DO PERIODO, SEM O VALOR POR ITEM e TUDO NA MESMA LINHA) 
      evite adicionar qualquer coisa alem disso e me de o texto com os itens sempre um embaixo do outro.
      `,
      temperature: 1,
      max_tokens: 700,
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,
    })
    
    
    return response.data.choices[0].text.trim();

};

export const GerarMetaDiaria = async (obj) => {
  const response = await openai.createCompletion({
      model: "text-davinci-003",
      prompt: `
      - ${obj.altura}m de altura;
      - ${obj.kg}kg;
      - Com o objetivo de ${obj.objetivo};
      - Com intolerancia a: ${obj.intolerancia}.
      Com essas informaçoes, me de a quantidade de proteinas, carboidratos e lipidio que preciso ingerir por dia.
      Me de nesse formato: 
      Proteína: quantidade em g
      Carboidrato: quantiade em g
      Lipídio: quantiade em g

      Me retorne sempre com os nomes igual te passei, primeira letra maiuscula e no singular
      
      `,
      temperature: 1,
      max_tokens: 100,
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,
    })
    

    //console.log(response.data.choices[0].text.trim())
    return response.data.choices[0].text.trim()
};


//v1/chat/completions

export const GerarDieta992 = async (infoUsuario) => {
  const response = await openai.createCompletion({
      model: "gpt-4-0613",
      prompt: `Faça uma dieta para os dias segunda, terça, quarta, quinta e sexta com varias opções de refeição por periodo (nao repetir),(nao incluir besteira no café da manha) informe os alimentos em GRAMAS. COLOQUE ALIMENTOS DO DIA A DIA (troque suco por frutas) Sou do sexo ${infoUsuario.genero}, ${infoUsuario.kg}kg, ${infoUsuario.altura}m, objetivo: ${infoUsuario.objetivo}. Observações: NÃO INCLUA ${infoUsuario.naoGosto}. e Inclua: ${infoUsuario.gosto}. Formato: Horarios e quantidade (em gramas).`,
      temperature: 1,
      max_tokens: 3400,
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,
    })
    

  return response.data.choices[0].text.trim();

};

const api_key = process.env.REACT_APP_OPENAI_API_KEY

export const GerarDieta990 = async (infoUsuario) => {
  try {
    const response = await axios.post(
      'https://api.openai.com/v1/chat/completions',
      {
        model: "gpt-4-0613",
        max_tokens: 6500,
        messages: [
          {
            "role": "assistant",
            "content": "Mantenha um tom profissional de nutricionista e seja objetivo na resposta. NÃO MANDE REPETIR AS REFEIÇOES DE DIAS ANTERIORES POR FAVOR!! E TAMBEM NAO MANDE COMENTARIOS ADICIONAIS POR FAVOR!!"
          },
          {
            "role": "user",
            "content": `Faça uma dieta para todos os dias segunda, terça, quarta, quinta e sexta com 2 opções de refeição por periodo, sendo eles café da manha, almoço, lanche e jantar (não deve se repetir o cardapio de nenhum dia),(nao incluir besteira no café da manha) informe os alimentos em GRAMAS. COLOQUE ALIMENTOS DO DIA A DIA (troque suco por frutas) Sou do sexo ${infoUsuario.genero}, ${infoUsuario.kg}kg, ${infoUsuario.altura}m, objetivo: ${infoUsuario.objetivo} e tenho ${infoUsuario.idade} anos de idade. Observações: NÃO INCLUA ${infoUsuario.naoGosto}. e Inclua: ${infoUsuario.gosto}. Formato: Horarios e quantidade (em gramas). Por favor, não inclua mensagens DEPOIS DA ULTIMA OPÇÃO DE JANTA DA SEXTA. Por favor tambem, não adicione asterisco em nenhum lugar do texto, isso atrapalha. NÃO ADICIONE COMENTARIOS EXTRAS, ISSO ATRAPALHA. NÃO MANDE REPETIR AS REFEIÇOES DE DIAS ANTERIORES POR FAVOR!!`
          },
        ]
      },
      {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`
        }
      }
    );
      console.log(response.data.choices[0].message.content)
    return response.data.choices[0].message.content
  } catch (error) {
    console.error('Erro ao chamar a API do OpenAI:', error);
    throw error; // ou trate o erro conforme necessário para sua aplicação
  }
};

// curl https://api.openai.com/v1/chat/completions \
//   -H "Content-Type: application/json" \
//   -H "Authorization: Bearer $OPENAI_API_KEY" \
//   -d '{
    // "model": "gpt-4-0613",
    // "messages": [
    //   {
    //     "role": "system",
    //     "content": "You are a helpful assistant."
    //   },
    //   {
    //     "role": "user",
    //     "content": "Hello!"
    //   }
    // ]
//   }'

//Faça uma dieta para todos os dias da semana com varias opções de refeição (nao repetir),(nao incluir besteira no café da manha) informe os alimentos em GRAMAS. COLOQUE ALIMENTOS DO DIA A DIA (troque suco por frutas) Sou do sexo ${infoUsuario.genero}, ${infoUsuario.kg}kg, ${infoUsuario.altura}m, objetivo: ${infoUsuario.objetivo}. Observações: NÃO INCLUA ${infoUsuario.naoGosto}. e Inclua: ${infoUsuario.gosto}. Formato: CALORIAS, os horarios e as quantidades (em gramas).

//Faça ma dieta para segunda, terça, quarta, quinta e sexta com varias opções de refeição (nao repetir), informe os alimentos em GRAMAS. COLOQUE ALIMENTOS DO DIA A DIA (troque suco por frutas) Sou homem, 80kg, 1.65m, objetivo: Emagrecer com Jejum intermitente. Observações: NÃO INCLUA (). e INCLUA: . Formato: .
    
    

//`Cliente: "Preciso de uma dieta de 7 dias para meu objetivo de ${infoUsuario.objetivo}, tenho ${infoUsuario.altura}m de altura e ${infoUsuario.kg}kg e sou do sexo ${infoUsuario.genero}. Intolerancia - ${infoUsuario.intolerancia}. ${infoUsuario.gosta && infoUsuario.gosta != [] && `Nessa dieta não pode faltar(IMPORTANTE) os seguintes alimentos: ${infoUsuario.gosta}.`}${infoUsuario.naoGosta && infoUsuario.naoGosta != [] && `Não coloque os seguintes alimentos: ${infoUsuario.naoGosta}, detesto esses alimentos(ISSO É MUITO IMPORTANTE).`} Tambem quero que monte uma serie de treinos para meu objetivo de ${infoUsuario.objetivo}."
//Profissional: `