import React from "react";
import CardPerfil from "../../components/CardPerfil";

const Perfil = () => {
    return(
        <>
            <CardPerfil/>
        </>
    )

}

export default Perfil