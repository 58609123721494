const firebaseConfig = {
  apiKey: "AIzaSyCNRzJxBIBWjr4XcNstxdwk24Yi5NiTyLU",
  authDomain: "nutrafity.firebaseapp.com",
  databaseURL: "https://nutrafity-default-rtdb.firebaseio.com",
  projectId: "nutrafity",
  storageBucket: "nutrafity.appspot.com",
  messagingSenderId: "237840510930",
  appId: "1:237840510930:web:cf862553c957cd7314620b",
  measurementId: "G-PLY6EHBVWD"
};


export default firebaseConfig