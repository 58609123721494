import React from "react";
import CardEsqueciSenha from "../../components/CardEsqueciSenha";

const ForgotPassword = () => {
    
    return(
        <>
            <CardEsqueciSenha/>
        </>
    )
}

export default ForgotPassword