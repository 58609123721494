export const alimentos = [
    // { label: "Maçã", value: "Maçã" },
    // { label: "Banana", value: "Banana" },
    // { label: "Jiló", value: "Jiló" },
    // { label: "Fígado", value: "Fígado" },
    // { label: "Beterraba", value: "Beterraba" },
    // { label: "Quiabo", value: "Quiabo" },
    // { label: "Acelga", value: "Acelga" },
    // { label: "Couve-de-Bruxelas", value: "Couve-de-Bruxelas" },
    // { label: "Pimentão", value: "Pimentão" },
    // { label: "Chuchu", value: "Chuchu" },
    // { label: "Nabo", value: "Nabo" },
    // { label: "Abóbora", value: "Abóbora" },
    // { label: "Grão-de-bico", value: "Grão-de-bico" },
    // { label: "Couve-flor", value: "Couve-flor" },
    // { label: "Berinjela", value: "Berinjela" },
    // { label: "Maxixe", value: "Maxixe" },
    // { label: "Inhame", value: "Inhame" },
    // { label: "Cogumelos (algumas variedades)", value: "Cogumelos" },
    // { label: "Pimenta Malagueta", value: "Pimenta Malagueta" },
    // { label: "Alho-poró", value: "Alho-poró" },
    // { label: "Anchova", value: "Anchova" },
    // { label: "Sardinha (em lata)", value: "Sardinha" },
    // { label: "Mexilhões", value: "Mexilhões" },
    // { label: "Ostras", value: "Ostras" },
    // { label: "Salsicha", value: "Salsicha" },
    // { label: "Tripas", value: "Tripas" },
    // { label: "Ameixa seca", value: "Ameixa seca" },
    // { label: "Uva-passa", value: "Uva-passa" },
    // { label: "Lentilhas", value: "Lentilhas" },
    // { label: "Repolho", value: "Repolho" },
    // { label: "Rabanete", value: "Rabanete" },
    // { label: "Chá de boldo (bebida amarga)", value: "Chá de boldo" },
    // { label: "Arroz", value: "Arroz" },
    // { label: "Feijão", value: "Feijão" },
    // { label: "Carne (em várias preparações)", value: "Carne" },
    // { label: "Mandioca (aipim)", value: "Mandioca" },
    // { label: "Farofa", value: "Farofa" },
    // { label: "Coxinha", value: "Coxinha" },
    // { label: "Brigadeiro", value: "Brigadeiro" },
    // { label: "Pastel", value: "Pastel" },
    // { label: "Pão de queijo", value: "Pão de queijo" },
    // { label: "Açaí", value: "Açaí" },
    // { label: "Tapioca", value: "Tapioca" },
    // { label: "Moqueca (de peixe ou camarão)", value: "Moqueca" },
    // { label: "Cuscuz (tanto o nordestino quanto o paulista)", value: "Cuscuz" },
    // { label: "Acarajé", value: "Acarajé" },
    // { label: "Caipirinha", value: "Caipirinha" },
    // { label: "Tapioca", value: "Tapioca" },
    // { label: "Queijo coalho (na churrasqueira)", value: "Queijo coalho" },
    // { label: "Bobó de camarão", value: "Bobó de camarão" },
    // { label: "Canjica", value: "Canjica" },
    // { label: "Quindim", value: "Quindim" },
    // { label: "Feijoada", value: "Feijoada" },
    // { label: "Cachorro-quente", value: "Cachorro-quente" },
    // { label: "Pamonha", value: "Pamonha" },
    // { label: "Escondidinho (com carne-seca ou frango)", value: "Escondidinho" },
    // { label: "Doce de leite", value: "Doce de leite" },
    // { label: "Brigadeiro", value: "Brigadeiro" },
    // { label: "Beijinho", value: "Beijinho" },
    // { label: "Quindim", value: "Quindim" },
    // { label: "Cocada", value: "Cocada" },
    // { label: "Bolo de chocolate", value: "Bolo de chocolate" },
    // { label: "Pudim", value: "Pudim" },
    // { label: "Paçoca", value: "Paçoca" },
    // { label: "Canjica", value: "Canjica" },
    // { label: "Curau", value: "Curau" },
    // { label: "Pé-de-moleque", value: "Pé-de-moleque" },
    // { label: "Cajuzinho", value: "Cajuzinho" },
    // { label: "Bolo de milho", value: "Bolo de milho" },
    // { label: "Goiabada com queijo", value: "Goiabada com queijo" },
    // { label: "Palha italiana", value: "Palha italiana" },
    // { label: "Maria-mole", value: "Maria-mole" },
    // { label: "Ambrosia", value: "Ambrosia" },
    // { label: "Doce de leite", value: "Doce de leite" },
    // { label: "Beijinho de coco", value: "Beijinho de coco" },
    // { label: "Romeu e Julieta (goiabada com queijo branco)", value: "Romeu e Julieta" },
    // { label: "Torta de limão", value: "Torta de limão" },
    // { label: "Doce de abóbora", value: "Doce de abóbora" },
    // { label: "Quindim", value: "Quindim" },
    // { label: "Torta de morango", value: "Torta de morango" },
    // { label: "Arroz doce", value: "Arroz doce" },
    // { label: "Torta de maracujá", value: "Torta de maracujá" }
  ];
  

export const objetivos = [
    { label: 'Emagrecer', value: 'Emagrecer' },
    { label: "Ganho de Massa Muscular", value: "Ganho de Massa Muscular"},
    { label: "Definição Muscular", value: "Definicao Muscular" },
    { label: "Jejum Intermitente", value: "Jejum Intermitente"},
    { label: "Melhorar a alimentação", value: "Melhorar a alimentacao" },
    { label: "Aumentar a Performance fisica", value: "Aumentar a Performance fisica" },
    { label: 'Hipertrofia', value: 'Hipertrofia'},
]

export const observacoes = [
    {label: "Calorias + Horarios", value: "Calorias + Horarios"},
    {label: "Horarios + Quantidades", value: "Horarios + Quantidades"},
]